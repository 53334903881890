import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectReadOnly, selectPermissions, selectVulnDetailId, selectAttachments
} from 'store/Manage/selectors';
import UploadEvidence from './components/UploadEvidence';
import Attachment from './components/Attachment';
import Wrapper from './styled';
import api from 'services/api';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

const EvidenceTab = ({ isEditing }) => {
  const [evidences, setEvidences] = useState({});
  const attachments = useSelector(selectAttachments);
  const vulnDetailId = useSelector(selectVulnDetailId);
  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const allowed = isEditing || permissions.update.allowed;
  const workspace = useSelector(selectCurrentWorkspace);
  const files = isEditing ? attachments : evidences;

  const getEvidences = useCallback(async () => {
    try {
      const data = await api.attachments.getAttachments(workspace, vulnDetailId);
      setEvidences(data);
    } catch (e) {
      setEvidences({});
    }
  }, [workspace, vulnDetailId]);

  const deleteEvidence = useCallback(async (name) => {
    try {
      await api.attachments.removeAttachments(workspace, vulnDetailId, name);
      const newEvidences = { ...evidences };
      delete newEvidences[name];
      setEvidences(newEvidences);
    } catch (e) {
      setEvidences(evidences);
    }
  }, [evidences, workspace, vulnDetailId]);

  useEffect(() => {
    getEvidences();
  }, [getEvidences]);

  return (
    <>
    <Wrapper>
      {!readOnly && allowed && <UploadEvidence getEvidences={ getEvidences } isEditing={ isEditing } /> }
      {Object.keys(files).map((key) => <Attachment key={ key } deleteEvidence={ deleteEvidence } evidence={ evidences[key] } name={ key } isEditing={ isEditing } />)}
    </Wrapper>
    </>
  );
};

export default EvidenceTab;
