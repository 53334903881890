import { MANAGE_CREATE_UPDATE_SUCCESS } from 'Screens/ManageEditCreate/actions/Actions';

import {
  GET_VULNS_COUNT_SUCCEED_DASHBOARD,
  GET_VULNS_COUNT_FAILURE_DASHBOARD,
  GET_TOP_SERVICES_SUCCEED_DASHBOARD,
  GET_TOP_SERVICES_FAILURE_DASHBOARD,
  GET_TOP_HOSTS_FAILURE_DASHBOARD,
  GET_TOP_HOSTS_SUCCEED_DASHBOARD,
  GET_SUMMARY_SUCCEED_DASHBOARD,
  GET_SUMMARY_FAILURE_DASHBOARD,
  GET_ACTIVITY_FEED_SUCCEED_DASHBOARD,
  GET_ACTIVITY_FEED_FAILURE_DASHBOARD,
  GET_LATEST_VULNS_SUCCEED_DASHBOARD,
  GET_LATEST_VULNS_FAILURE_DASHBOARD,
  SHOW_MODAL_IMPORT_DASHBOARD,
  RELOAD_FEED,
  GET_VULNS_BY_STATUS_REQUEST,
  GET_VULNS_BY_STATUS_SUCCESS,
  GET_VULNS_BY_STATUS_FAILURE,
  GET_VULNS_BY_SEVERITY_REQUEST,
  GET_VULNS_BY_SEVERITY_SUCCESS,
  GET_VULNS_BY_SEVERITY_FAILURE,
  GET_COMMAND_HISTORY_START,
  GET_COMMAND_HISTORY_SUCCEED,
  GET_COMMAND_HISTORY_FAILURE,
  GET_STATS_FILTERED_START,
  GET_STATS_FILTERED_SUCCESS,
  GET_STATS_FILTERED_FAILURE,
  GET_WS_STATS_START,
  GET_WS_STATS_SUCCESS
} from 'Screens/Dashboard/actions/Actions';

import defaultTools from './tools';

const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  extraWorkspaces: [],
  reloadSummary: false,
  reloadActivityFeed: false,
  reloadTopHosts: false,
  reloadTopService: false,
  reloadVulnerabilities: false,
  reloadLatestVulns: false,
  tools: defaultTools,
  showImportFileModal: false,
  vulnCountByStatus: {
    open: 0,
    closed: 0,
    reopen: 0,
    riskAccepted: 0
  },
  vulnCountBySeverity: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    informational: 0,
    unclassified: 0
  },
  commandHistory: []
};

function dashboard (state = initialState, action) {
  switch (action.type) {
    case GET_VULNS_COUNT_SUCCEED_DASHBOARD: {
      const tools = state.tools;
      tools[0].data = action.data;
      tools[0].error = '';

      return {
        ...state,
        reloadVulnerabilities: false,
        tools
      };
    }

    case GET_VULNS_COUNT_FAILURE_DASHBOARD: {
      const tools = state.tools;
      tools[0].data = [];
      tools[0].error = action.error;

      return {
        ...state,
        reloadVulnerabilities: false,
        tools
      };
    }

    case GET_TOP_SERVICES_SUCCEED_DASHBOARD: {
      const tools = state.tools;
      tools[1].data = action.data;
      tools[1].error = '';

      return {
        ...state,
        reloadTopService: false,
        tools
      };
    }

    case GET_TOP_SERVICES_FAILURE_DASHBOARD: {
      const tools = state.tools;
      tools[1].data = [];
      tools[1].error = action.error;

      return {
        ...state,
        reloadTopService: false,
        tools
      };
    }

    case GET_TOP_HOSTS_SUCCEED_DASHBOARD: {
      const tools = state.tools;
      tools[2].data = action.data;
      tools[2].error = '';

      return {
        ...state,
        reloadTopHosts: false,
        tools
      };
    }

    case GET_TOP_HOSTS_FAILURE_DASHBOARD: {
      const tools = state.tools;
      tools[2].data = [];
      tools[2].error = action.error;

      return {
        ...state,
        reloadTopHosts: false,
        tools
      };
    }

    case GET_SUMMARY_SUCCEED_DASHBOARD: {
      const tools = state.tools;
      tools[3].data = action.data;
      tools[3].error = '';

      return {
        ...state,
        reloadSummary: false,
        tools
      };
    }

    case GET_SUMMARY_FAILURE_DASHBOARD: {
      const tools = state.tools;
      tools[3].data = [];
      tools[3].error = action.error;

      return {
        ...state,
        reloadSummary: false,
        tools
      };
    }

    case GET_ACTIVITY_FEED_SUCCEED_DASHBOARD: {
      const tools = state.tools;
      tools[4].data = action.data;
      tools[4].error = '';
      return {
        ...state,
        reloadActivityFeed: false,
        tools
      };
    }

    case GET_ACTIVITY_FEED_FAILURE_DASHBOARD: {
      const tools = state.tools;
      tools[4].data = [];
      tools[4].error = action.error;

      return {
        ...state,
        reloadActivityFeed: false,
        tools
      };
    }

    case GET_COMMAND_HISTORY_START: {
      return {
        ...state,
        isFetching: true,
        error: false,
        errorMessage: ''
      };
    }

    case GET_COMMAND_HISTORY_SUCCEED: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
        commandHistory: action.data
      };
    }

    case GET_COMMAND_HISTORY_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }

    case MANAGE_CREATE_UPDATE_SUCCESS: {
      return {
        ...initialState,
        reloadSummary: true,
        reloadActivityFeed: true,
        reloadTopHosts: true,
        reloadTopService: true,
        reloadVulnerabilities: true,
        reloadLatestVulns: true,
        showImportFileModal: state.showImportFileModal
      };
    }

    case GET_LATEST_VULNS_SUCCEED_DASHBOARD: {
      const tools = state.tools;
      tools[5].data = action.data;
      tools[5].error = '';

      return {
        ...state,
        reloadLatestVulns: false,
        tools
      };
    }

    case GET_LATEST_VULNS_FAILURE_DASHBOARD: {
      const tools = state.tools;
      tools[5].data = [];
      tools[5].error = action.error;

      return {
        ...state,
        reloadLatestVulns: false,
        tools
      };
    }

    case SHOW_MODAL_IMPORT_DASHBOARD: {
      return {
        ...state,
        showImportFileModal: action.value
      };
    }

    case RELOAD_FEED: {
      return {
        ...state,
        reloadSummary: true,
        reloadActivityFeed: true,
        reloadTopHosts: true,
        reloadTopService: true,
        reloadVulnerabilities: true,
        reloadLatestVulns: true
      };
    }

    case GET_VULNS_BY_STATUS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_VULNS_BY_STATUS_SUCCESS: {
      const vulnCountByStatus = {
        open: action.data.groups.find((item) => (item.status === 'open')) ? action.data.groups.find((item) => (item.status === 'open')).count : 0,
        closed: action.data.groups.find((item) => (item.status === 'closed')) ? action.data.groups.find((item) => (item.status === 'closed')).count : 0,
        reopen: action.data.groups.find((item) => (item.status === 're-opened')) ? action.data.groups.find((item) => (item.status === 're-opened')).count : 0,
        riskAccepted: action.data.groups.find((item) => (item.status === 'risk-accepted')) ? action.data.groups.find((item) => (item.status === 'risk-accepted')).count : 0
      };
      return {
        ...state,
        vulnCountByStatus,
        isFetching: false
      };
    }

    case GET_VULNS_BY_STATUS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }

    case GET_VULNS_BY_SEVERITY_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_VULNS_BY_SEVERITY_SUCCESS: {
      const vulnCountBySeverity = {
        critical: action.data.groups.find((item) => (item.severity === 'critical')) ? action.data.groups.find((item) => (item.severity === 'critical')).count : 0,
        high: action.data.groups.find((item) => (item.severity === 'high')) ? action.data.groups.find((item) => (item.severity === 'high')).count : 0,
        medium: action.data.groups.find((item) => (item.severity === 'med')) ? action.data.groups.find((item) => (item.severity === 'med')).count : 0,
        low: action.data.groups.find((item) => (item.severity === 'low')) ? action.data.groups.find((item) => (item.severity === 'low')).count : 0,
        informational: action.data.groups.find((item) => (item.severity === 'info')) ? action.data.groups.find((item) => (item.severity === 'info')).count : 0,
        unclassified: action.data.groups.find((item) => (item.severity === 'unclassified')) ? action.data.groups.find((item) => (item.severity === 'unclassified')).count : 0
      };
      return {
        ...state,
        vulnCountBySeverity,
        isFetching: false
      };
    }

    case GET_VULNS_BY_SEVERITY_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }

    case GET_STATS_FILTERED_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_STATS_FILTERED_SUCCESS: {
      const tools = state.tools;
      tools[3].data = action.data;
      tools[3].error = '';

      return {
        ...state,
        tools
      };
    }

    case GET_STATS_FILTERED_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }

    case GET_WS_STATS_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_WS_STATS_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }

    default: {
      return state;
    }
  }
}

export default dashboard;
