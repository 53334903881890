import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin: 9px 0px 17px;
  flex-direction: column;
  background-color: ${colors.white};
  border: 1px solid #e4e9ee;
  border-left: 2px solid ${colors.blue5};
  border-radius: 2px;
  padding: 18px 23px 16px 21px;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex; 
`;
Content.displayName = 'Content';

export const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.blue5};
  margin-right: 5px;
`;
Label.displayName = 'Label';

export const Data = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.dark4};
`;
Data.displayName = 'Data';

export const RunBy = styled.div`
  display: flex;
  line-height: 1.93;
  width: 50%;
`;
RunBy.displayName = 'RunBy';

export const Duration = styled.div`
  display: flex; 
  line-height: 1.93;
`;
Duration.displayName = 'Duration';

export const DateTime = styled.div`
  line-height: 1.93;
  margin-top: 19px;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.blueGrey};
`;
DateTime.displayName = 'DateTime';
