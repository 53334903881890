import { copyArray, copy } from '../../Common/Functions/Copy';

import {
  RESET_STATE_TEMPLATE_CREATE_UPDATE,
  ADD_REPORT_TEMPLATE_CREATE_UPDATE,
  REMOVE_REPORT_TEMPLATE_CREATE_UPDATE,
  SET_REDIRECT_TEMPLATE_CREATE_UPDATE,
  SET_FIELD_NAME_TEMPLATE_CREATE_UPDATE,
  SELECT_CHECK_BOX_TEMPLATE_CREATE_UPDATE,
  REMOVE_REFERENCE_CREATE_UPDATE,
  REMOVE_POLICY_CREATE_UPDATE,
  ADD_REFERENCE_CREATE_UPDATE,
  ADD_POLICY_CREATE_UPDATE,
  SET_TEMPLATE_NAME_TEMPLATE_CREATE_UPDATE,
  TEMPLATE_CREATE_UPDATE_START,
  TEMPLATE_CREATE_UPDATE_FAIL,
  TEMPLATE_CREATE_UPDATE_SUCCESS,
  SET_TEMPLATE_CREATE_UPDATE,
  SET_CUSTOM_ATTRIBUTE_KB_CREATE_UPDATE
} from '../../Screens/KnowledgeBaseEditCreate/actions/Actions';

const initialState = {
  id: undefined,
  _id: undefined,
  name: '',
  description: '',
  external_id: '',
  references: [],
  reference: '',
  data: '',
  resolution: '',
  accountability: false,
  availability: false,
  confidentiality: false,
  integrity: false,
  exploitation: '',
  easeofresolution: '',
  policyviolations: [],
  policy: '',
  creator: '',
  create_at: '',
  isFetching: false,
  errorMessage: undefined,
  redirect: false,
  mode: '',
  report: [],
  importSuccess: false,
  successMessage: '',
  ownder: '',
  default_gateway: 'None',
  customfields: {}
};

function knowledgeBaseEditCreate (state = initialState, action) {
  switch (action.type) {
    case RESET_STATE_TEMPLATE_CREATE_UPDATE: {
      return {
        ...initialState
      };
    }

    case ADD_REPORT_TEMPLATE_CREATE_UPDATE: {
      const report = copyArray(state.report);
      report.push(action.file);

      return {
        ...state,
        report
      };
    }

    case REMOVE_REPORT_TEMPLATE_CREATE_UPDATE: {
      const report = copyArray(state.report);
      report.splice(action.index, 1);

      return {
        ...state,
        report
      };
    }

    case SET_REDIRECT_TEMPLATE_CREATE_UPDATE: {
      return {
        ...state,
        redirect: true,
        importSuccess: false
      };
    }

    case SET_FIELD_NAME_TEMPLATE_CREATE_UPDATE: {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }

    case SELECT_CHECK_BOX_TEMPLATE_CREATE_UPDATE: {
      return {
        ...state,
        [action.checkName]: !state[action.checkName]
      };
    }

    case REMOVE_REFERENCE_CREATE_UPDATE: {
      const { references } = state;
      references.splice(action.index, 1);

      return {
        ...state,
        references
      };
    }

    case REMOVE_POLICY_CREATE_UPDATE: {
      const { policyviolations } = state;
      policyviolations.splice(action.index, 1);

      return {
        ...state,
        policyviolations
      };
    }

    case ADD_REFERENCE_CREATE_UPDATE: {
      if (state.reference) {
        const references = [...state.references];
        references.push(state.reference);

        return {
          ...state,
          references,
          reference: ''
        };
      }

      return {
        ...state
      };
    }

    case ADD_POLICY_CREATE_UPDATE: {
      if (state.policy) {
        const policyviolations = [...state.policyviolations];
        policyviolations.push(state.policy);

        return {
          ...state,
          policyviolations,
          policy: ''
        };
      }

      return {
        ...state
      };
    }

    case SET_TEMPLATE_NAME_TEMPLATE_CREATE_UPDATE: {
      const { templatenames } = state;
      templatenames[action.index] = action.value;

      return {
        ...state,
        templatenames
      };
    }

    case TEMPLATE_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true,
        errorMessage: ''
      };
    }

    case TEMPLATE_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case TEMPLATE_CREATE_UPDATE_SUCCESS: {
      return {
        ...initialState
      };
    }

    case SET_TEMPLATE_CREATE_UPDATE: {
      return {
        ...state,
        isFetching: false,
        id: action.template.id,
        name: action.template.name,
        description: action.template.description,
        external_id: action.template.external_id,
        references: action.template.refs,
        reference: '',
        data: action.template.data,
        resolution: action.template.resolution,
        accountability: action.template.impact.accountability,
        availability: action.template.impact.availability,
        confidentiality: action.template.impact.confidentiality,
        integrity: action.template.impact.integrity,
        exploitation: action.template.exploitation,
        easeofresolution: action.template.easeofresolution,
        policyviolations: action.template.policyviolations,
        policy: '',
        customfields: action.template.customfields
      };
    }

    case SET_CUSTOM_ATTRIBUTE_KB_CREATE_UPDATE: {
      const customfields = copy(state.customfields);
      customfields[action.key] = action.value;

      return {
        ...state,
        customfields
      };
    }

    default:
      return state;
  }
}

export default knowledgeBaseEditCreate;
