import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`

`;
Wrapper.displayName = 'Wrapper';

export const AditionalText = styled.div`
    width: 220px;
    height: 36px;    
    font-size: 13.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    letter-spacing: 0.1px;
    color: #afb1bc;
    margin-left: 20px;
    margin-top: 10px;
`;
AditionalText.displayName = 'AditionalText';

export const DropdownsContainer = styled.div`
    display: flex;
    margin-left: 17px;
    align-self: flex-end;
`;
DropdownsContainer.displayName = 'DropdownsContainer';

export const RedDot = styled.div`
    color: ${colors.redPink};
    padding-top: 9px;
    margin-left: 3px;
`;
RedDot.displayName = 'RedDot';

export const Black = styled.div`
    color: #0b2128;
    display: inline;
`;
Black.displayName = 'Black';

export const Row = styled.div`
    display:flex;
    gap:20px;
`;

export const PolicyColumn = styled.div`
    width: 49%;
`;
PolicyColumn.displayName = 'PolicyColumn';
