import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import some from 'lodash/some';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import IconButton from 'Common/Components/IconButton';
import Trash from './styled';
import DeleteConfirmationModal from '../../DeleteConfirmationModal';
import ModalWarning from 'Common/Components/ModalWarning';

// eslint-disable-next-line import/prefer-default-export
export const WsDeleteButton = () => {
  const selectedWs = useSelector(selectWorkspacesSelected);
  const currentWs = useSelector(selectCurrentWorkspace);
  const [showWarning, setShowWarning] = useState();
  const [showConfirmation, setShowConfirmation] = useState();

  const onClick = () => {
    if (some(selectedWs, { name: currentWs })) setShowWarning(true);
    else setShowConfirmation(true);
  };

  const onCloseWarning = () => setShowWarning(false);

  const onCloseConfirmation = () => setShowConfirmation(false);

  return (
    <>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ onClick } />
      { showConfirmation && <DeleteConfirmationModal onCloseCallback={ onCloseConfirmation } /> }
      { showWarning && <ModalWarning onCloseCallback={ onCloseWarning } title={ 'Delete Workspace' } description={ 'The Workspace you´re currently using can´t be deleted. Switch to a different Workspace and retry.' } /> }
    </>
  );
};
