import {
  HIDE_FILE_UPLOAD_CONTEXT,
  ADD_COMMAND,
  UPDATE_COMMAND_LIST_STATUS
} from 'Common/Components/FileUploadContext/actions';

const initialState = {
  commandsInProcess: []
};

function fileUploaderContext (state = initialState, action) {
  switch (action.type) {
    case HIDE_FILE_UPLOAD_CONTEXT: {
      return {
        ...initialState
      };
    }

    case ADD_COMMAND: {
      return {
        ...state,
        commandsInProcess: [
          ...state.commandsInProcess,
          action.command
        ]
      };
    }

    case UPDATE_COMMAND_LIST_STATUS: {
      return {
        ...state,
        commandsInProcess: action.data
      };
    }

    default: {
      return state;
    }
  }
}

export default fileUploaderContext;
