import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileUploader from 'Common/Components/FileUploader';
import { uploadFile, setFilesCount } from 'Screens/ManageEditCreate/actions/Actions';
import { selectCurrentUploadState, selectCurrentUploadError, selectFileIndex } from 'store/ManageEditCreate/selectors';

const UploadStep = ({ files, ignoreInfo, resolveHost }) => {
  const dispatch = useDispatch();
  const fileIndex = useSelector(selectFileIndex);
  const currentUploadState = useSelector(selectCurrentUploadState);
  const currentUploadError = useSelector(selectCurrentUploadError);

  const params = [
    { name: 'file', value: files[fileIndex] },
    { name: 'ignore_info', value: ignoreInfo },
    { name: 'resolve_hostname', value: resolveHost }
  ];

  const uploadFileManage = () => {
    dispatch(setFilesCount(files));
    if (fileIndex >= 0) dispatch(uploadFile(params));
  };

  return (
    <FileUploader
      onManage
      uploadFile={ uploadFileManage }
      currentUploadState={ currentUploadState }
      currentUploadError={ currentUploadError }
      filenames={ files.map((f) => (f.name)).join(', ') }
    />
  );
};

export default UploadStep;
