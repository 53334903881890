import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectShowInactive, selectWorkspacesOrdered, selectWorkspacesSelected
} from 'store/Workspace/selectors';
import some from 'lodash/some';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import WorkspaceItem from './components/WorkspaceItem';
import { Wrapper } from './styled';
import { getData } from 'Screens/Workspaces/actions/Actions';

const GridView = ({ setShowConfirmation, setShowWarning }) => {
  const workspaceList = useSelector(selectWorkspacesOrdered);
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const showInactive = useSelector(selectShowInactive);
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  return (
    <Wrapper>
      { workspaceList.filter((ws) => ws.active).map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } setShowConfirmation={ setShowConfirmation } setShowWarning={ setShowWarning } />) }
      { showInactive && workspaceList.filter((ws) => ws.name !== currentWorkspace && !ws.active).map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } />) }
    </Wrapper>
  );
};

export default GridView;
