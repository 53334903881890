import React, { useState } from 'react';
import {
  Wrapper, Critical, High, Medium
} from './styled';

const Severity = ({ value, className }) => {
  const [showPercentage, setShowPercentage] = useState(true);
  const critical = value.critical || 0;
  const high = value.high || 0;
  const medium = value.med || 0;
  const total = value.total || 0;

  const getPercentage = (severityCount, totalVulns) => {
    if (severityCount > 0) return Math.floor(((severityCount / totalVulns) * 100));
  };

  const criticalPercentage = getPercentage(critical, total);
  const highPercentage = getPercentage(high, total);
  const mediumPercentage = getPercentage(medium, total);

  const getLabel = (percentage, severityCount) => {
    if (percentage >= 10) {
      if (showPercentage) return `${percentage}%`;
      else return severityCount;
    }
  };

  const getTooltip = (percentage) => {
    if (percentage < 10) return `${percentage}%`;
  };

  const handleClick = () => setShowPercentage(!showPercentage);

  return (
    <Wrapper className={ className }>
     { critical ? <Critical onClick={ handleClick } width={ criticalPercentage } title={ getTooltip(criticalPercentage) }>{getLabel(criticalPercentage, critical)}</Critical> : null}
     { high ? <High onClick={ handleClick } width={ highPercentage } title={ getTooltip(highPercentage) }>{getLabel(highPercentage, high)}</High> : null}
     { medium ? <Medium onClick={ handleClick } width={ mediumPercentage } title={ getTooltip(mediumPercentage) }>{getLabel(mediumPercentage, medium)}</Medium> : null }
    </Wrapper>
  );
};

export default Severity;
