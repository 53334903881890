/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DateFormatter from 'Common/Components/DateFormatter';
import Skeleton from 'Common/Components/Skeleton';
import get from 'lodash/get';
import {
  Expander, Icon, Hostnames, SeverityColumn, CenteredText, CenteredNumericRow,
  CenteredIcon
} from './styled';
import {
  CheckColum, CheckHeader, IpColumn
} from './components';
import ImportanceColumn from './components/ImportanceColumn';

const oss = ['windows', 'cisco', 'router', 'osx', 'apple', 'mac', 'linux', 'unix'];
function getHost (h) {
  return h ? oss.find((os) => (h.toLowerCase().indexOf(os) > -1 ? os : '')) : '';
}

const getDataOrPlaceholder = (value) => (value && value.length > 0 ? value : '');

const getNumericDataOrPlaceholder = (number) => (number && number > 0 ? number : '');

const getHostnameString = (hostnames, groupBy, isPivot) => {
  if (hostnames.length === 0 || (groupBy && !isPivot)) { return ''; }
  const hostnameArray = hostnames.split(',');
  return hostnameArray.length > 1 ? `${hostnameArray[0]} + ${hostnameArray.length - 1}` : hostnameArray[0];
};

export default function getColumns (isPivot, fields, groupBy, isFetching) {
  const columns = [{
    Header: () => groupBy && !isPivot && <></>,
    id: 'expander',
    show: !!groupBy && !isPivot,
    sortable: false,
    filterable: false,
    expander: true,
    minWidth: 50,
    custom_order: -1,
    Expander: ({ isExpanded, original }) => {
      const groupingBy = get(original, groupBy, '-');
      return (
        <Expander isExpanded={ isExpanded }>
          {!isFetching && `${groupingBy} (${original.count})`}
        </Expander>
      );
    }
  }, {
    Header: () => (!groupBy || (groupBy && isPivot)) && <CheckHeader />,
    id: 'selected',
    accessor: 'Selected',
    show: (!groupBy || (groupBy && isPivot)),
    sortable: false,
    resizable: false,
    maxWidth: 38,
    minWidth: 38,
    style: { display: 'flex' },
    Cell: (rowInfo) => ((isFetching) ? <Skeleton /> : (!groupBy || isPivot) && <CheckColum host={ rowInfo.original } />)
  }, {
    Header: () => (
      <CenteredText>
        <FormattedMessage id="host.table.columns.important" />
      </CenteredText>
    ),
    id: 'importance',
    accessor: 'importance',
    show: (fields.important.visible || !groupBy || (groupBy && isPivot)),
    sortable: !groupBy,
    resizable: false,
    width: 90,
    // minWidth: 50,
    custom_order: fields.important.order,
    Cell: (rowInfo) => ((isFetching) ? <Skeleton /> : (!groupBy || isPivot) && <ImportanceColumn host={ rowInfo.original } />)

  },
  {
    Header: () => <FormattedMessage id="host.table.columns.ip" />,
    id: 'ip',
    accessor: 'ip',
    show: fields.asset.visible && groupBy !== 'ip',
    sortable: !groupBy,
    resizable: !groupBy,
    width: 200,
    custom_order: fields.asset.order,
    Cell: (rowInfo) => ((isFetching) ? <Skeleton /> : <IpColumn host={ rowInfo.original }>{getDataOrPlaceholder(rowInfo.original.ip, groupBy)}</IpColumn>)
  }, {
    Header: () => <CenteredText><FormattedMessage id="host.table.columns.search" /></CenteredText>,
    id: 'search',
    accessor: 'search',
    show: fields.search.visible,
    sortable: false,
    resizable: false,
    custom_order: fields.search.order,
    Cell: (rowInfo) => (
      (isFetching)
        ? <Skeleton />
        : !groupBy && rowInfo.original.ip
            ? (
          <a href={ `//shodan.io/search?query=${rowInfo.original.ip}` } target="_blank" rel="noopener noreferrer" tooltip="Search in Shodan">
            <Icon alt="Search in Shodan" type="shodan" />
          </a>
              )
            : null
    ),
    maxWidth: fields.search.maxWidth
  }, {
    Header: () => (
      <CenteredText>
        <FormattedMessage id="host.table.columns.os" />
      </CenteredText>
    ),
    id: 'os',
    accessor: 'os',
    show: fields.os.visible && groupBy !== 'os',
    sortable: !groupBy,
    resizable: false,
    custom_order: fields.os.order,
    Cell: (rowInfo) => {
      const os = getHost(rowInfo.original.os);
      return (
        (isFetching)
          ? <Skeleton />
          : (!groupBy || (groupBy && isPivot)) && (
            <CenteredIcon>
              <Icon
                alt={ `${os}` }
                type={ os }
              />
            </CenteredIcon>
            )
      );
    },
    width: 38
  }, {
    Header: () => <FormattedMessage id="host.table.columns.services" />,
    id: 'services',
    show: fields.services.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.services.order,
    Cell: (rowInfo) => ((isFetching) ? <Skeleton /> : getDataOrPlaceholder(rowInfo.original.service_summaries && rowInfo.original.service_summaries.join(', '))),
    maxWidth: 150,
    getProps: () => ({ 'column-type': 'services', 'className': `min-wd-${fields.services.defaultMin}` })
  }, {
    Header: () => <FormattedMessage id="host.table.columns.hostnames" />,
    id: 'hostnames',
    show: fields.hostnames.visible,
    sortable: false,
    resizable: !groupBy,
    custom_order: fields.hostnames.order,
    Cell: (rowInfo) => (isFetching
      ? <Skeleton />
      : rowInfo.original.hostnames
        ? (
      <Hostnames>{ getDataOrPlaceholder(getHostnameString(rowInfo.original.hostnames.join(', '), groupBy, isPivot)) }</Hostnames>
          )
        : ''),
    minWidth: 200,
    maxWidth: 300,
    getProps: () => ({ 'column-type': 'hostnames', 'className': `min-wd-${fields.hostnames.defaultMin}` })
  }, {
    Header: () => <FormattedMessage id="host.table.columns.open_services" />,
    id: 'open_service_count',
    accesor: 'open_services',
    show: fields.open_services.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.open_services.order,
    Cell: (rowInfo) => (
      (isFetching) ? <Skeleton /> : <CenteredNumericRow>{ getNumericDataOrPlaceholder(rowInfo.original.services, groupBy) } </CenteredNumericRow>
    ),
    maxWidth: fields.open_services.maxWidth,
    minWidth: fields.open_services.minWidth
  }, {
    Header: () => (
      <CenteredText>
        <FormattedMessage id="host.table.columns.vulns" />
      </CenteredText>
    ),
    id: 'vulnerability_count',
    accessor: 'vulns',
    show: fields.vulns.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.vulns.order,
    width: fields.vulns.minWidth,
    Cell: (rowInfo) => ((isFetching) ? <Skeleton /> : <CenteredNumericRow>{getNumericDataOrPlaceholder(rowInfo.original.vulns, groupBy)}</CenteredNumericRow>)
  }, {
    Header: () => <CenteredText><FormattedMessage id="host.table.columns.owned" /></CenteredText>,
    id: 'owned',
    accessor: 'owned',
    show: fields.owned.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.owned.order,
    Cell: (rowInfo) => ((isFetching)
      ? <Skeleton />
      : <CenteredText>
          {rowInfo.original.owned ? (rowInfo.original.owned ? 'Yes' : 'Not Yet') : ''}
        </CenteredText>),
    maxWidth: 80,
    getProps: () => ({ 'column-type': 'owned', 'className': `min-wd-${fields.owned.defaultMin}` })
  }, {
    Header: () => <FormattedMessage id="host.table.columns.description" />,
    id: 'description',
    accessor: 'description',
    show: fields.description.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.description.order,
    Cell: (rowInfo) => (
      (isFetching) ? <Skeleton /> : getDataOrPlaceholder(rowInfo.original.description, groupBy)
    ),
    minWidth: 165,
    maxWidth: 210,
    getProps: () => ({ 'column-type': 'description', 'className': `min-wd-${fields.description.defaultMin}` })
  }, {
    Header: () => <FormattedMessage id="host.table.columns.mac" />,
    id: 'mac',
    accessor: 'mac',
    show: fields.mac.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.mac.order,
    Cell: (rowInfo) => (
      (isFetching) ? <Skeleton /> : getDataOrPlaceholder(rowInfo.original.mac, groupBy)
    ),
    minWidth: 120,
    maxWidth: 150,
    getProps: () => ({ 'column-type': 'mac', 'className': `min-wd-${fields.mac.defaultMin}` })
  }, {
    Header: () => (
      <CenteredText>
        <FormattedMessage id="host.table.columns.owner" />
      </CenteredText>
    ),
    id: 'creator__username',
    accesor: 'owner',
    show: fields.owner.visible && groupBy !== 'owner',
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.owner.order,
    Cell: (rowInfo) => (
      (isFetching)
        ? <Skeleton />
        : (
          <CenteredText>
            {getDataOrPlaceholder(rowInfo.original.owner, groupBy)}
          </CenteredText>
          )
    ),
    width: 70
  }, {
    Header: () => <FormattedMessage id="host.table.columns.creation_time" />,
    id: 'create_date',
    show: fields.creation_time.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.creation_time.order,
    Cell: (rowInfo) => (
      (isFetching) ? <Skeleton /> : rowInfo.original.metadata && rowInfo.original.metadata.create_time ? <DateFormatter date={ rowInfo.original.metadata.create_time } /> : ''
    ),
    minWidth: fields.creation_time.minWidth,
    maxWidth: fields.creation_time.maxWidth
  }, {
    Header: () => (
      <CenteredText>
        <FormattedMessage id="host.table.columns.last_modified" />
      </CenteredText>
    ),
    id: 'update_date',
    show: fields.last_modified.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.last_modified.order,
    Cell: (rowInfo) => (
      (isFetching)
        ? <Skeleton />
        : (
          <CenteredIcon>
            {rowInfo.original.metadata && rowInfo.original.metadata.update_time ? <DateFormatter date={ rowInfo.original.metadata.update_time } /> : ''}
          </CenteredIcon>
          )
    ),
    width: 140
  }, {
    Header: () => <FormattedMessage id="host.table.columns.severity_counts" />,
    id: 'severity_counts',
    show: fields.severity_counts.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.severity_counts.order,
    Cell: (rowInfo) => (
      (isFetching) ? <Skeleton /> : (rowInfo.original.severity_counts && <SeverityColumn value={ rowInfo.original.severity_counts } />) || ''
    ),
    minWidth: fields.severity_counts.minWidth,
    maxWidth: fields.severity_counts.maxWidth,
    getProps: () => ({ 'column-type': 'severity_counts', 'className': `min-wd-${fields.severity_counts.defaultMin}` })
  }, {
    Header: () => (
      <CenteredText>
        <FormattedMessage id="host.table.columns.id" />
      </CenteredText>
    ),
    id: 'id',
    accesor: '_id',
    show: fields.id.visible,
    sortable: !groupBy,
    resizable: !groupBy,
    custom_order: fields.id.order,
    Cell: (rowInfo) => (
      (isFetching)
        ? <Skeleton />
        : (
          <CenteredText>
            {getNumericDataOrPlaceholder(rowInfo.original.id)}
          </CenteredText>
          )
    ),
    width: 80,
    getProps: () => ({ 'column-type': 'id', 'className': `min-wd-${fields.id.defaultMin}` })
  }];

  return columns.filter((row, index) => row.show === true || index === 0).sort((a, b) => (a.custom_order < b.custom_order ? -1 : 1));
}
